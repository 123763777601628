<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1"/>
      </el-tab-pane>
      <!--      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">-->
      <!--        <childTab3 :isShow="isSho" @draftState="draftState"/>-->
      <!--      </el-tab-pane>-->
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RequEditMain from '@/views/DevelopManagement/RequManage/TabChild/RequEditMain';
import RequEditAbout from '@/views/DevelopManagement/RequManage/TabChild/RequEditAbout';

export default {
  name: 'RequEdit',
  components: {
    childTab1: RequEditMain,
    childTab2: RequEditAbout,
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {
  },
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped lang="scss"></style>
